// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  BASE_URL: 'https://api.ebiz-qual-int.basf.com',
  FEDERATION_AUTH_URL: 'https://federation-qa.basf.com/nidp/oauth/nam/authz',
  OAUTH_CLIENT_ID: '5168fc6e-4026-476a-8dc1-73bf1efc3199',
  SCOPE: 'APN-EBIZ_QUAL',
  EBIZ_ROLE: 'cn=APN_EBIZ_2023_Q_EBIZ,ou=SYNCGROUPS,ou=GLOBALGROUPS,o=AUTH',
  EBILL_ROLE: 'cn=APN_EBIZ_2023_Q_EBILL,ou=SYNCGROUPS,ou=GLOBALGROUPS,o=AUTH',
  OAUTH_REDIRECT_URI: 'https://ebiz-qual-int.basf.com/index.html',
  ENV_URL:'https://ebiz-qual-int.basf.com/'
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
